body{
  font-family: 'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
}

li.ant-menu-item{
  padding-left: 12px !important;
}

li.ant-menu-item-selected.ant-menu-item-only-child{
  border-radius: 0px !important;
}
.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
/* Modal styling */
span.ant-modal-close-x{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  width: 40px !important;
  height: 40px !important;
  background: #FFFFFF;
  border: 1px solid #CFD9E0;
  border-radius: 6px !important;

}

button.ant-modal-close{
  width: 40px !important;
  height: 40px !important;  
}

div.ant-modal-header{
  border-bottom: 1px solid #CFD9E0;
  height: 70px !important;
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-right  : 24px !important;


}
div.ant-modal-content{
  padding: 0px !important;
}
div.ant-modal-body{
  padding: 0px !important;
 
}


#google-login-button > button, #google-logout-button > button {
  margin-top: 10px;
  width: 100%;
  text-align: center ;
}

#google-login-button > button > span, #google-logout-button > button > span  {
  display: block;
  width: 100%;
  text-align: center;
}

#google-logout-button > button > div  {
  display: none;
}